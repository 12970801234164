import { render, staticRenderFns } from "./IDC-award.vue?vue&type=template&id=37c13d8a&scoped=true&"
import script from "./IDC-award.vue?vue&type=script&lang=js&"
export * from "./IDC-award.vue?vue&type=script&lang=js&"
import style0 from "./IDC-award.vue?vue&type=style&index=0&id=37c13d8a&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/_vue-loader@15.9.8@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "37c13d8a",
  null
  
)

export default component.exports