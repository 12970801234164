<style lang="less" scoped>
.person-center {
  width: 100%;
  height: 100%;
  background: #070911;
  color: #dee6ff;
  &-wrapper {
    max-width: 1112px;
    margin: 0 auto;
    &-nav {
      width: 100%;
      height: 60px;
      background: #0f1118;
      display: flex;
      justify-content: flex-start;
      &-item {
        width: 150px;
        line-height: 60px;
        text-align: center;
        cursor: pointer;
        font-size: 14px;
        font-weight: 500;
        color: rgba(222, 230, 255, 1);
        img {
          width: 20px;
          vertical-align: middle;
        }
      }
    }
    &-selfInfo {
      width: 100%;
      padding: 20px 0;
      margin-top: 10px;
      background: #0f1118;
      &-title {
        width: 100%;
        padding-top: 28px;
        display: flex;
        justify-content: flex-start;
        height: 40px;
        margin-left: 5%;
        font-size: 20px;
        // line-height: 40px;
        &-left {
          width: 4px;
          height: 20px;
          background: rgba(85, 119, 224, 1);
          border-radius: 5px;
        }
        &-right {
          font-size: 18px;
          font-weight: 400;
          color: rgba(85, 119, 224, 1);
          margin-left: 10px;
        }
      }
      &-item {
        width: 90%;
        margin-top: 10px;
        margin-left: 5%;
        font-size: 14px;
        font-family: "PingFangSC-Regular", "PingFang SC";
        font-weight: 400;
        color: rgba(222, 230, 255, 1);
        line-height: 24px;
      }
      .person-center-wrapper-selfInfo-item-dasai {
        width: 85%;
        background: rgba(23, 25, 32, 1);
        padding: 20px 24px;
        p {
          margin: 0;
          font-family: "PingFangSC-Regular", "PingFang SC";
          color: rgba(222, 230, 255, 1);
          font-weight: 400;
          &:nth-child(1) {
            height: 20px;
            font-size: 14px;
            line-height: 20px;
          }
          &:nth-child(2) {
            font-size: 12px;
            line-height: 24px;
          }
        }
      }
      .person-center-wrapper-selfInfo-item-xize {
        width: 89%;
        display: flex;
        justify-content: space-between;
        &-item {
          width: 30%;
          height: 230px;
          background: rgba(23, 25, 32, 1);
          padding-top: 20px;
          padding-left: 24px;
          p {
            margin: 0;
            font-family: "PingFangSC-Regular", "PingFang SC";
            font-weight: 400;
            color: rgba(222, 230, 255, 1);
            &:nth-child(1) {
              font-size: 14px;
              line-height: 20px;
            }
            &:nth-child(2) {
              margin-top: 20px;
              font-size: 12px;
              line-height: 24px;
            }
          }
        }
      }
      .person-center-wrapper-selfInfo-item-richeng {
        width: 89%;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        &-item {
          width: 30.3%;
          height: 84px;
          background: rgba(23, 25, 32, 1);
          padding-left: 20px;
          margin-top: 15px;
          font-family: "PingFangSC-Regular", "PingFang SC";
          font-weight: 400;
          color: rgba(222, 230, 255, 1);
          &-top {
            display: flex;
            padding-top: 15px;
            &-left {
              width: 20px;
              height: 20px;
              background: center / contain no-repeat url("../assets/9.png");
              text-align: center;
              line-height: 20px;
            }
            &-right {
              margin-left: 13px;
            }
          }
          &-main {
            font-size: 12px;
            line-height: 24px;
            margin-top: 10px;
          }
        }
      }
      .person-center-wrapper-selfInfo-jiang {
        width: 85%;
        background: rgba(23, 25, 32, 1);
        text-align: center;
        padding: 10px;
        font-size: 12px;
        font-family: "PingFangSC-Regular", "PingFang SC";
        font-weight: 400;
        color: rgba(222, 230, 255, 1);
        line-height: 17px;
        margin-top: 1px;
      }
      .person-center-wrapper-selfInfo-flex {
        width: 85%;
        background: rgba(23, 25, 32, 1);
        text-align: center;
        padding: 10px;
        font-size: 12px;
        font-family: "PingFangSC-Regular", "PingFang SC";
        font-weight: 400;
        color: rgba(222, 230, 255, 1);
        margin-top: 0px;
        display: flex;
        justify-content: space-between;
        &-left {
          width: 26.6%;
        }
        &-right {
          width: 74%;
          text-align: left;
          margin-left: 1px;
        }
      }
      .person-center-wrapper-selfInfo-download {
        display: flex;
        justify-content: space-between;
        img {
          width: 20px;
          cursor: pointer;
        }
      }
    }
    &-button {
      width: 140px;
      height: 36px;
      text-align: center;
      line-height: 36px;
      margin: 0 auto;
      background: linear-gradient(
        211deg,
        rgba(248, 40, 108, 1) 0%,
        rgba(249, 98, 47, 1) 100%
      );
      border-radius: 18px;
      cursor: pointer;
    }
  }
  .actived {
    font-size: 14px;
    font-weight: 600;
    color: rgba(85, 119, 224, 1);
    background: rgba(30, 33, 43, 1);
    border-bottom: 2px solid rgba(85, 119, 224, 1);
  }
  .r-button {
    width: 140px;
    height: 40px;
    margin: 28px auto;
    text-align: center;
    line-height: 40px;
    cursor: pointer;
    background: linear-gradient(
      211deg,
      rgba(248, 40, 108, 1) 0%,
      rgba(249, 98, 47, 1) 100%
    );
    border-radius: 29px;
  }
  /deep/ .el-input__inner {
    height: 30px;
    border: 1px solid #999;
    border-radius: 0px;
    background: #070911;
  }
  /deep/ .el-checkbox__inner {
    width: 16px;
    height: 16px;
    background: rgba(15, 17, 24, 1);
    border: 1px solid rgba(222, 230, 255, 0.3);
  }
}
</style>

<template>
  <div class="person-center">
    <div class="person-center-wrapper">
      <!-- <div class="person-center-wrapper-nav">
        <div class="person-center-wrapper-nav-item"
             v-for="(item, index) in personCenterNav"
             :key="index"
             :class="index === activedIndex ? 'actived' : ''">
          <img :src="item.img"
               alt="">
          {{item.name}}
        </div>
      </div> -->
      <div class="person-center-wrapper-selfInfo">
        <div class="person-center-wrapper-selfInfo-title">
          <div class="person-center-wrapper-selfInfo-title-left">
          </div>
          <div class="person-center-wrapper-selfInfo-title-right">
            {{ $t('Three33') }}
          </div>
        </div>
        <div class="person-center-wrapper-selfInfo-item">
          <!-- {{$t('Three34')}}<br />
          {{$t('Three35')}}<br />
          {{$t('Three36')}} -->
          <template v-if="$Cookies.get('cecc-lang') === 'en'">
            YRD City Creative Economy Cooperation Committee(CECC) is a regional inter-governmental agency approved by YRD City Economy Coordination Council (‘the Council’), comprising eight senior leaders from Shanghai, Jiangsu Province, Zhejiang Province and Anhui Province.The Council holds an annual meeting in different cities, attended by mayors or vice mayors. The Executive President is based in Shanghai and the permanent Liaison Office is hosted by Shanghai Municipal Government Reforimg and Development Office (more details can be found at: (http://cecc.sh.cn/en/page/html/company.php).<br />
            The plan builds on previous initiatives for YRD, the first of which was in 1992, when 15 cities established a joint mechanism for economic cooperation. In 2018, the integration of the YRD was upgraded to a national-level strategy. (http://www.chinadaily.com.cn/regional/2020-06/01/content_37536491.htm)<br />
            YRD City Creative Economy Cooperation Committee(CECC) is a regional inter-governmental agency approved by YRD City Economy Coordination Council (‘the Council’), comprising eight senior leaders from Shanghai, Jiangsu Province, Zhejiang Province and Anhui Province.The Council holds an annual meeting in different cities, attended by mayors or vice mayors. The Executive President is based in Shanghai and the permanent Liaison Office is hosted by Shanghai Municipal Government Reforimg and Development Office (more details can be found at: (http://cecc.sh.cn/en/page/html/company.php).
          </template>
          <template v-else>
            为深入贯彻落实习近平总书记重要讲话精神，奋力夺取疫情防控和实现长三角经济社会发展目标“双胜利”，在长三角城市经济协调会办公室指导下，由长三角创意经济专业委员会（简称CECC）牵头组织长三角全球创意设计大赛及其专属“锦绣江南”名品榜活动。该活动已被列入长三角城市经济协调会2020年度重点合作事项清单，同时列入长三角城市经济协调会2021年度工作计划的重点事项和重要内容。2020年长三角全球创意设计大赛不仅被浙江省、专委联盟选为创新案例，大赛平台单位还协助大赛参赛选手产品进行落地实践，切实推动了成果转化。<br />
            全球创意设计大赛定位为长三角世界级城市群建设的重要组成部分，将持续推动设计成果产业化转化和商业化运作，延伸设计产业价值链；同时，将继续加重“上海设计”国际化元素，站位全球设计价值链发声，构建“上海设计+长三角产业链”一体化高质量发展模式，汇聚全球创意英才，传播长三角“原创品牌”，向世界讲述“中国故事”。
          </template>
        </div>
        <div class="person-center-wrapper-selfInfo-title">
          <div class="person-center-wrapper-selfInfo-title-left">
          </div>
          <div class="person-center-wrapper-selfInfo-title-right">
            {{$t('goal')}}
          </div>
        </div>
        <div class="person-center-wrapper-selfInfo-item">
          {{$t('g1')}} <br />
          {{$t('g2')}} <br />
          {{$t('g3')}} <br />
          {{$t('g4')}} <br />
          {{$t('g5')}}
        </div>
        <div class="person-center-wrapper-selfInfo-title"
             v-if="$Cookies.get('cecc-lang') !== 'en'">
          <div class="person-center-wrapper-selfInfo-title-left">
          </div>
          <div class="person-center-wrapper-selfInfo-title-right">
            大赛主题
          </div>
        </div>
        <div class="person-center-wrapper-selfInfo-item"
             v-if="$Cookies.get('cecc-lang') !== 'en'">
          <!-- {{$t('g1')}} <br />
          {{$t('g2')}} <br />
          {{$t('g3')}} <br />
          {{$t('g4')}} <br />
          {{$t('g5')}} -->
          大赛持续以“Power of Design”为核心价值理念，主题定位于“绿色生活，城市IP”，倡导生活的可持续性设计应用，集聚世界创意资源，依据长三角城市特色打造城市独特IP，并将其设计实现商业转化。
        </div>
        <div class="person-center-wrapper-selfInfo-title">
          <div class="person-center-wrapper-selfInfo-title-left">
          </div>
          <div class="person-center-wrapper-selfInfo-title-right">
            {{$t('ThreeTHEMES')}}
          </div>
        </div>
        <div class="person-center-wrapper-selfInfo-item">
          {{$t('ThreeTHEMES1')}} <br />
          {{$t('ThreeTHEMES2')}}
        </div>
        <div class="person-center-wrapper-selfInfo-item person-center-wrapper-selfInfo-item-dasai"
             style="{height: '210px'}">
          <p>
            {{$t('ThreeTHEMES3')}}
          </p>
          <p>
            <!-- {{$t('ThreeTHEMES4')}} <br />
            {{$t('ThreeTHEMES5')}} <br />
            {{$t('ThreeTHEMES6')}} <br />
            {{$t('ThreeTHEMES7')}} <br />
            {{$t('ThreeTHEMES8')}} -->
            <template v-if="$Cookies.get('cecc-lang') === 'en'">
              ➢ Culture and Creative Design <br />
              ➢ Industry Design e.g. product design, package design, visual design and <br />
              ➢ Environment design <br />
              ➢ Fashion Design <br />
              ➢ Innovative Design(e.g. digital products, new materials)
            </template>
            <template v-else>
              以设计作品参赛的赛事模块，分为以自由探索为尝试的概念组和以落地实践为目标的产业组。<br />
              主要包含文创设计、时尚设计、工业设计（含产品设计、包装设计、视觉设计等）、创新设计（含数字设计、新材料设计等新设计）四大板块的参赛方向。<br />
              城市印象文创类、食品、生活用品、化妆品、服装、珠宝行业应用优先。<br />
              将不定期推出“企业设计需求”项目，面向长三角城市、产业园区及企业的设计需求设置命题赛事，具体细节分类详见报名网站“大赛简介”详情页。<br />
              对于优秀的面向落地的设计作品，大赛组委会将协助推荐入驻长三角地区个城市企业总部园区，提供相应的产业孵化资金、场地等的支持。具体以各园区政策为准。
            </template>
          </p>
        </div>
        <div class="person-center-wrapper-selfInfo-item person-center-wrapper-selfInfo-item-dasai"
             style="{height: '162px'}">
          <p>
            {{$t('ThreeTHEMES9')}}
          </p>
          <p>
            <!-- {{$t('ThreeTHEMES10')}} <br />
            {{$t('ThreeTHEMES11')}} <br />
            {{$t('ThreeTHEMES12')}} -->
            <template v-if="$Cookies.get('cecc-lang') === 'en'">
              ➢ Environmental technology and products technology<br />
              ➢ Health products<br />
              ➢ Artificial Intelligence Products<br />
              ➢ Creative Design
            </template>
            <template v-else>
              以“创业商业计划书”参赛，并进行项目路演的赛事模块，主要面向项目孵化需求的创意设计、数字智能、医疗健康等相关项目。<br />
              【优先支持】数字智能项目、创意设计项目、医疗健康项目。对于优秀的创业项目，大赛组委会将协助推荐入驻长三角地区个城市企业总部园区，提供相应的产业孵化资金、场地等的支持。具体以各园区政策为准。
            </template>
          </p>
        </div>
        <div class="person-center-wrapper-selfInfo-item person-center-wrapper-selfInfo-item-dasai"
             style="{height: '162px'}">
          <p>
            {{$t('ThreeTHEMES13')}}
          </p>
          <p>
            <!-- {{$t('ThreeTHEMES14')}}<br />
            {{$t('ThreeTHEMES15')}}<br />
            {{$t('ThreeTHEMES16')}} -->
            <template v-if="$Cookies.get('cecc-lang') === 'en'">
              The winning projects in this theme will obtain a “green channel” to open an e-shop on T-mall and other major e-commerce platforms and receive support from the Top 10 Taobao partner operation and marketing companies.<br />
              Alibaba T-mall was launched as a spin-off from Taobao in April 2008for business-to-consumer online retail. It is operated in China by Alibaba Groupfor both local Chinese and international businesses to sell brand name goods to consumers mainly in China but also internationally. . It has over 500 million monthly active users and 180,000 brands. It is the world's third most visited website according to Alexa. (https://expandedramblings.com/index.php/tmall-statistics/)
            </template>
            <template v-else>
              以已上市作品、产品及品牌参赛，并进行作品汇报的赛事模块，主要面向品牌上线需求的设计作品与产品及品牌。【参赛方向与行业不限。】<br />
              【优先支持】原创品牌项目、拟拓展市场通路项目。
            </template>
          </p>
        </div>
        <div class="person-center-wrapper-selfInfo-title">
          <div class="person-center-wrapper-selfInfo-title-left">
          </div>
          <div class="person-center-wrapper-selfInfo-title-right">
            {{$t('ThreeTHEMES17')}}
          </div>
        </div>
        <div class="person-center-wrapper-selfInfo-item person-center-wrapper-selfInfo-item-xize">
          <div class="person-center-wrapper-selfInfo-item-xize-item">
            <p>
              {{$t('ThreeTHEMES18')}}
            </p>
            <p>
              {{$t('ThreeTHEMES19')}}<br />
              {{$t('ThreeTHEMES20')}}<br />
              {{$t('ThreeTHEMES21')}}<br />
              {{$t('ThreeTHEMES22')}}<br />
              {{$t('ThreeTHEMES23')}}<br />
              {{$t('ThreeTHEMES24')}}
            </p>
          </div>
          <div class="person-center-wrapper-selfInfo-item-xize-item">
            <p>
              {{$t('ThreeTHEMES25')}}
            </p>
            <p>
              {{$t('ThreeTHEMES26')}}<br />
              {{$t('ThreeTHEMES27')}}<br />
              {{$t('ThreeTHEMES28')}}<br />
              {{$t('ThreeTHEMES29')}}<br />
              {{$t('ThreeTHEMES30')}}<br />
              {{$t('ThreeTHEMES31')}}
            </p>
          </div>
          <div class="person-center-wrapper-selfInfo-item-xize-item">
            <p>
              {{$t('ThreeTHEMES32')}}
            </p>
            <p>
              {{$t('ThreeTHEMES33')}}<br />
              {{$t('ThreeTHEMES34')}}<br />
              {{$t('ThreeTHEMES35')}}<br />
              {{$t('ThreeTHEMES36')}}<br />
              {{$t('ThreeTHEMES37')}}<br />
            </p>
          </div>
        </div>
        <div class="person-center-wrapper-selfInfo-item person-center-wrapper-selfInfo-item-dasai"
             style="{height: '88px'}">
          <p>
          </p>
          <p>
            {{$t('H58')}}<br />
            {{$t('H59')}}
          </p>
        </div>
      </div>
      <div class="person-center-wrapper-selfInfo">
        <div class="person-center-wrapper-selfInfo-title">
          <div class="person-center-wrapper-selfInfo-title-left">
          </div>
          <div class="person-center-wrapper-selfInfo-title-right">
            {{$t('Three40')}}
          </div>
        </div>
        <div class="person-center-wrapper-selfInfo-item">
          {{$t('Three41')}} <br />
          {{$t('Three42')}} <br />
          {{$t('Three43')}}
        </div>
        <div class="person-center-wrapper-selfInfo-title">
          <div class="person-center-wrapper-selfInfo-title-left">
          </div>
          <div class="person-center-wrapper-selfInfo-title-right">
            {{$t('Three37')}}
          </div>
        </div>
        <div class="person-center-wrapper-selfInfo-item">
          {{$t('Three38')}} <br />
          {{$t('Three39')}}
        </div>
        <div class="person-center-wrapper-selfInfo-title">
          <div class="person-center-wrapper-selfInfo-title-left">
          </div>
          <div class="person-center-wrapper-selfInfo-title-right">
            {{$t('ThreeTHEMES39')}}
          </div>
        </div>
        <div class="person-center-wrapper-selfInfo-item"
             v-if="$Cookies.get('cecc-lang') !== 'en'">
          <!-- {{$t('ThreeTHEMES40')}} —— {{$t('ThreeTHEMES41')}} —— {{$t('ThreeTHEMES42')}} —— {{$t('ThreeTHEMES43')}} —— {{$t('ThreeTHEMES44')}} —— {{$t('ThreeTHEMES45')}} -->
          <!-- 作品征集 —— 上海“设计之都”活动周 —— 作品初评 —— 公布初评入围名单 —— 入围作品提交实物及演示视频 —— 复评 —— 总决赛 —— IDC颁奖典礼与商业转化对接 -->
          作品征集 —— 上海“设计之都”活动周 —— 作品初评 —— 公布初评入围名单 —— 入围作品提交实物及演示视频 —— 复评 —— 总决赛 —— IDC颁奖典礼与商业转化对接
        </div>
        <div class="person-center-wrapper-selfInfo-item person-center-wrapper-selfInfo-item-richeng"
             v-if="$Cookies.get('cecc-lang') === 'en'">
          <div class="person-center-wrapper-selfInfo-item-richeng-item">
            <div class="person-center-wrapper-selfInfo-item-richeng-item-top">
              <div class="person-center-wrapper-selfInfo-item-richeng-item-top-left">01</div>
              <div class="person-center-wrapper-selfInfo-item-richeng-item-top-right">
                <!-- {{$t('ThreeTHEMES40')}} -->
                Submission and Peer Review
              </div>
            </div>
            <div class="person-center-wrapper-selfInfo-item-richeng-item-main">
              <!-- {{$t('ThreeTHEMES46')}} -->
              December, 2021 – August, 2022
            </div>
          </div>
          <div class="person-center-wrapper-selfInfo-item-richeng-item">
            <div class="person-center-wrapper-selfInfo-item-richeng-item-top">
              <div class="person-center-wrapper-selfInfo-item-richeng-item-top-left">02</div>
              <div class="person-center-wrapper-selfInfo-item-richeng-item-top-right">
                <!-- {{$t('ThreeTHEMES42')}} -->
                Feedback to Nominees
              </div>
            </div>
            <div class="person-center-wrapper-selfInfo-item-richeng-item-main">
              July, 2022 – October, 2022
            </div>
          </div>
          <div class="person-center-wrapper-selfInfo-item-richeng-item">
            <div class="person-center-wrapper-selfInfo-item-richeng-item-top">
              <div class="person-center-wrapper-selfInfo-item-richeng-item-top-left">03</div>
              <div class="person-center-wrapper-selfInfo-item-richeng-item-top-right">
                Final proposal submission
              </div>
            </div>
            <div class="person-center-wrapper-selfInfo-item-richeng-item-main">
              November, 2022
            </div>
          </div>
          <div class="person-center-wrapper-selfInfo-item-richeng-item">
            <div class="person-center-wrapper-selfInfo-item-richeng-item-top">
              <div class="person-center-wrapper-selfInfo-item-richeng-item-top-left">04</div>
              <div class="person-center-wrapper-selfInfo-item-richeng-item-top-right">
                Award Ceremony
              </div>
            </div>
            <div class="person-center-wrapper-selfInfo-item-richeng-item-main">
              December, 2022
            </div>
          </div>
        </div>
        <div class="person-center-wrapper-selfInfo-item person-center-wrapper-selfInfo-item-richeng"
             v-else>
          <div class="person-center-wrapper-selfInfo-item-richeng-item">
            <div class="person-center-wrapper-selfInfo-item-richeng-item-top">
              <div class="person-center-wrapper-selfInfo-item-richeng-item-top-left">01</div>
              <div class="person-center-wrapper-selfInfo-item-richeng-item-top-right">
                <!-- {{$t('ThreeTHEMES40')}} -->
                作品征集
              </div>
            </div>
            <div class="person-center-wrapper-selfInfo-item-richeng-item-main">
              <!-- {{$t('ThreeTHEMES46')}} -->
              2021年12月15日 — 2022年08月30日
            </div>
          </div>
          <div class="person-center-wrapper-selfInfo-item-richeng-item">
            <div class="person-center-wrapper-selfInfo-item-richeng-item-top">
              <div class="person-center-wrapper-selfInfo-item-richeng-item-top-left">02</div>
              <div class="person-center-wrapper-selfInfo-item-richeng-item-top-right">
                <!-- {{$t('ThreeTHEMES41')}} -->
                上海“设计之都”活动周
              </div>
            </div>
            <div class="person-center-wrapper-selfInfo-item-richeng-item-main">
              <!-- {{$t('ThreeTHEMES47')}} -->
              2022年09月
            </div>
          </div>
          <div class="person-center-wrapper-selfInfo-item-richeng-item">
            <div class="person-center-wrapper-selfInfo-item-richeng-item-top">
              <div class="person-center-wrapper-selfInfo-item-richeng-item-top-left">03</div>
              <div class="person-center-wrapper-selfInfo-item-richeng-item-top-right">
                <!-- {{$t('ThreeTHEMES42')}} -->
                作品初评
              </div>
            </div>
            <div class="person-center-wrapper-selfInfo-item-richeng-item-main">
              <!-- {{$t('ThreeTHEMES48')}} -->
              2022年09月01日 — 2022年09月30日
            </div>
          </div>
          <div class="person-center-wrapper-selfInfo-item-richeng-item">
            <div class="person-center-wrapper-selfInfo-item-richeng-item-top">
              <div class="person-center-wrapper-selfInfo-item-richeng-item-top-left">04</div>
              <div class="person-center-wrapper-selfInfo-item-richeng-item-top-right">
                <!-- {{$t('ThreeTHEMES43')}} -->
                公布初评入围名单
              </div>
            </div>
            <div class="person-center-wrapper-selfInfo-item-richeng-item-main">
              <!-- {{$t('ThreeTHEMES49')}} -->
              2022年10月05日
            </div>
          </div>
          <div class="person-center-wrapper-selfInfo-item-richeng-item">
            <div class="person-center-wrapper-selfInfo-item-richeng-item-top">
              <div class="person-center-wrapper-selfInfo-item-richeng-item-top-left">05</div>
              <div class="person-center-wrapper-selfInfo-item-richeng-item-top-right">
                <!-- {{$t('ThreeTHEMES44')}} -->
                入围作品提交实物及演示视频
              </div>
            </div>
            <div class="person-center-wrapper-selfInfo-item-richeng-item-main">
              <!-- {{$t('ThreeTHEMES50')}} -->
              2022年10月08日 — 2022年11月10日
            </div>
          </div>
          <div class="person-center-wrapper-selfInfo-item-richeng-item">
            <div class="person-center-wrapper-selfInfo-item-richeng-item-top">
              <div class="person-center-wrapper-selfInfo-item-richeng-item-top-left">06</div>
              <div class="person-center-wrapper-selfInfo-item-richeng-item-top-right">
                <!-- {{$t('ThreeTHEMES45')}} -->
                复评
              </div>
            </div>
            <div class="person-center-wrapper-selfInfo-item-richeng-item-main">
              <!-- {{$t('ThreeTHEMES51')}} -->
              2022年11月15日 — 2022年11月30日（暂定）
            </div>
          </div>
          <div class="person-center-wrapper-selfInfo-item-richeng-item">
            <div class="person-center-wrapper-selfInfo-item-richeng-item-top">
              <div class="person-center-wrapper-selfInfo-item-richeng-item-top-left">07</div>
              <div class="person-center-wrapper-selfInfo-item-richeng-item-top-right">
                <!-- {{$t('ThreeTHEMES45')}} -->
                总决赛
              </div>
            </div>
            <div class="person-center-wrapper-selfInfo-item-richeng-item-main">
              <!-- {{$t('ThreeTHEMES51')}} -->
              2022年12月
            </div>
          </div>
          <div class="person-center-wrapper-selfInfo-item-richeng-item">
            <div class="person-center-wrapper-selfInfo-item-richeng-item-top">
              <div class="person-center-wrapper-selfInfo-item-richeng-item-top-left">08</div>
              <div class="person-center-wrapper-selfInfo-item-richeng-item-top-right">
                <!-- {{$t('ThreeTHEMES45')}} -->
                IDC颁奖典礼与商业转化对接
              </div>
            </div>
            <div class="person-center-wrapper-selfInfo-item-richeng-item-main">
              <!-- {{$t('ThreeTHEMES51')}} -->
              2022年12月
            </div>
          </div>
        </div>
      </div>
      <div class="person-center-wrapper-selfInfo"
           :style="{height: '100%',paddingBottom: '18px'}">
        <div class="person-center-wrapper-selfInfo-title">
          <div class="person-center-wrapper-selfInfo-title-left">
          </div>
          <div class="person-center-wrapper-selfInfo-title-right">
            {{$t('ThreeTHEMES52')}}
          </div>
        </div>
        <div class="person-center-wrapper-selfInfo-item person-center-wrapper-selfInfo-jiang">
          {{$t('Three44')}}
        </div>
        <div class="person-center-wrapper-selfInfo-item person-center-wrapper-selfInfo-jiang">
          {{$t('ThreeTHEMES53')}}
        </div>
        <div class="person-center-wrapper-selfInfo-item person-center-wrapper-selfInfo-flex">
          <div class="person-center-wrapper-selfInfo-flex-left">
            {{$t('ThreeTHEMES54')}}
          </div>
          <div class="person-center-wrapper-selfInfo-flex-right">
            {{$t('ThreeTHEMES55')}}<br />
            {{$t('ThreeTHEMES56')}} <br />
            {{$t('ThreeTHEMES57')}}<br />
            {{$t('ThreeTHEMES58')}}
          </div>
        </div>
        <div class="person-center-wrapper-selfInfo-item person-center-wrapper-selfInfo-flex">
          <div class="person-center-wrapper-selfInfo-flex-left">
            {{$t('ThreeTHEMES59')}}
          </div>
          <div class="person-center-wrapper-selfInfo-flex-right">
            {{$t('ThreeTHEMES60')}}<br />
            {{$t('ThreeTHEMES61')}}<br />
            {{$t('ThreeTHEMES62')}} <br />
            {{$t('ThreeTHEMES63')}}
          </div>
        </div>
        <div class="person-center-wrapper-selfInfo-item person-center-wrapper-selfInfo-flex">
          <div class="person-center-wrapper-selfInfo-flex-left">
            {{$t('ThreeTHEMES64')}}
          </div>
          <div class="person-center-wrapper-selfInfo-flex-right">
            {{$t('ThreeTHEMES65')}}<br />
            {{$t('ThreeTHEMES66')}}<br />
            {{$t('ThreeTHEMES67')}}<br />
            {{$t('ThreeTHEMES68')}}
          </div>
        </div>
        <div class="person-center-wrapper-selfInfo-item person-center-wrapper-selfInfo-flex">
          <div class="person-center-wrapper-selfInfo-flex-left">
            {{$t('ThreeTHEMES69')}}
          </div>
          <div class="person-center-wrapper-selfInfo-flex-right">
            {{$t('ThreeTHEMES70')}}<br />
            {{$t('ThreeTHEMES71')}}<br />
            {{$t('ThreeTHEMES72')}}<br />
            {{$t('ThreeTHEMES73')}}
          </div>
        </div>
        <div class="person-center-wrapper-selfInfo-item person-center-wrapper-selfInfo-flex">
          <div class="person-center-wrapper-selfInfo-flex-left">
            {{$t('ThreeTHEMES74')}}
          </div>
          <div class="person-center-wrapper-selfInfo-flex-right">
            {{$t('ThreeTHEMES75')}}<br />
            {{$t('ThreeTHEMES76')}}<br />
            {{$t('ThreeTHEMES77')}}
          </div>
        </div>
        <div class="person-center-wrapper-selfInfo-item person-center-wrapper-selfInfo-flex">
          <div class="person-center-wrapper-selfInfo-flex-left">
          </div>
          <div class="person-center-wrapper-selfInfo-flex-right">
            {{$t('ThreeTHEMES78')}}
          </div>
        </div>
        <div class="person-center-wrapper-selfInfo-item person-center-wrapper-selfInfo-jiang">
          {{$t('ThreeTHEMES79')}}
        </div>
        <div class="person-center-wrapper-selfInfo-item person-center-wrapper-selfInfo-flex">
          <div class="person-center-wrapper-selfInfo-flex-left">
            {{$t('ThreeTHEMES80')}}
          </div>
          <div class="person-center-wrapper-selfInfo-flex-right">
            {{$t('ThreeTHEMES81')}}<br />
            {{$t('ThreeTHEMES82')}}<br />
            {{$t('ThreeTHEMES83')}}<br />
            {{$t('ThreeTHEMES84')}} <br />
            {{$t('ThreeTHEMES85')}} <br />
            {{$t('ThreeTHEMES86')}}
          </div>
        </div>
        <div class="person-center-wrapper-selfInfo-item person-center-wrapper-selfInfo-flex">
          <div class="person-center-wrapper-selfInfo-flex-left">
            {{$t('ThreeTHEMES87')}}
          </div>
          <div class="person-center-wrapper-selfInfo-flex-right">
            {{$t('ThreeTHEMES88')}}<br />
            {{$t('ThreeTHEMES89')}}<br />
            {{$t('ThreeTHEMES90')}}<br />
            {{$t('ThreeTHEMES91')}}<br />
            {{$t('ThreeTHEMES92')}}<br />
            {{$t('ThreeTHEMES93')}}
          </div>
        </div>
        <div class="person-center-wrapper-selfInfo-item person-center-wrapper-selfInfo-flex">
          <div class="person-center-wrapper-selfInfo-flex-left">
            {{$t('ThreeTHEMES94')}}
          </div>
          <div class="person-center-wrapper-selfInfo-flex-right">
            {{$t('ThreeTHEMES95')}}<br />
            {{$t('ThreeTHEMES96')}}<br />
            {{$t('ThreeTHEMES97')}} <br />
            {{$t('ThreeTHEMES98')}} <br />
            {{$t('ThreeTHEMES99')}}
          </div>
        </div>
        <div class="person-center-wrapper-selfInfo-item person-center-wrapper-selfInfo-flex">
          <div class="person-center-wrapper-selfInfo-flex-left">
            {{$t('Three1')}}
          </div>
          <div class="person-center-wrapper-selfInfo-flex-right">
            {{$t('Three2')}} <br />
            {{$t('Three3')}} <br />
            {{$t('Three4')}} <br />
            {{$t('Three5')}} <br />
            {{$t('Three6')}}
          </div>
        </div>
        <div class="person-center-wrapper-selfInfo-item person-center-wrapper-selfInfo-jiang">
          {{$t('Three7')}}
        </div>
        <div class="person-center-wrapper-selfInfo-item person-center-wrapper-selfInfo-flex">
          <div class="person-center-wrapper-selfInfo-flex-left">
            {{$t('Three9')}}
          </div>
          <div class="person-center-wrapper-selfInfo-flex-right">
            {{$t('Three10')}}<br />
            {{$t('Three11')}}<br />
            {{$t('Three12')}}<br />
            {{$t('Three13')}}<br />
            {{$t('Three14')}}<br />
            {{$t('Three15')}}
          </div>
        </div>
        <div class="person-center-wrapper-selfInfo-item person-center-wrapper-selfInfo-flex">
          <div class="person-center-wrapper-selfInfo-flex-left">
            {{$t('Three16')}}
          </div>
          <div class="person-center-wrapper-selfInfo-flex-right">
            {{$t('Three17')}}<br />
            {{$t('Three18')}}<br />
            {{$t('Three19')}} <br />
            {{$t('Three20')}} <br />
            {{$t('Three21')}} <br />
            {{$t('Three22')}}
          </div>
        </div>
        <div class="person-center-wrapper-selfInfo-item person-center-wrapper-selfInfo-flex">
          <div class="person-center-wrapper-selfInfo-flex-left">
            {{$t('Three23')}}
          </div>
          <div class="person-center-wrapper-selfInfo-flex-right">
            {{$t('Three24')}}<br />
            {{$t('Three25')}} <br />
            {{$t('Three26')}} <br />
            {{$t('Three27')}} <br />
            {{$t('Three28')}}
          </div>
        </div>
        <div class="person-center-wrapper-selfInfo-item person-center-wrapper-selfInfo-flex">
          <div class="person-center-wrapper-selfInfo-flex-left">
            {{$t('Three29')}}
          </div>
          <div class="person-center-wrapper-selfInfo-flex-right">
            {{$t('Three30')}} <br />
            {{$t('Three31')}} <br />
            {{$t('Three32')}} <br />
          </div>
        </div>
        <div class="person-center-wrapper-selfInfo-item">
          {{$t('Three8')}}
        </div>
        <div class="person-center-wrapper-selfInfo-item">
          <div class="r-button"
               @click="toProduction">
            {{$t('signUp')}}
          </div>
        </div>
      </div>
      <div class="person-center-wrapper-selfInfo"
           :style="{height: '218px'}">
        <div class="person-center-wrapper-selfInfo-title">
          <div class="person-center-wrapper-selfInfo-title-left">
          </div>
          <div class="person-center-wrapper-selfInfo-title-right">
            {{$t('P19')}}
          </div>
        </div>
        <div class="person-center-wrapper-selfInfo-item person-center-wrapper-selfInfo-download"
             v-for="(item, index) in downloadList"
             :key="index"
             @click="download(item)">
          <div class="person-center-wrapper-selfInfo-download-left">
            {{$t(item.name)}}
          </div>
          <div class="person-center-wrapper-selfInfo-download-right">
            <img src="../assets/idc-award/10.png"
                 alt="">
          </div>
        </div>
      </div>
    </div>
    <Common-footer :style="{background: '#0F1118'}"></Common-footer>
  </div>
</template>

<script>
import CommonFooter from '../components/common-footer';

export default {
  name: 'IDC-award',
  data () {
    return {
      personCenterNav: [{
        name: '参赛类别',
        img: require('../assets/idc-award/1.png')
      }, {
        name: '报名时间与费用',
        img: require('../assets/idc-award/2.png')
      }, {
        name: '奖项设置',
        img: require('../assets/idc-award/3.png')
      }, {
        name: '常见问题',
        img: require('../assets/idc-award/4.png')
      }, {
        name: '参赛须知',
        img: require('../assets/idc-award/5.png')
      }, {
        name: '免责条款',
        img: require('../assets/idc-award/6.png')
      }, {
        name: '资料下载',
        img: require('../assets/idc-award/7.png')
      }],
      activedIndex: 1,
      moreTips: [],
      userInfo: {},
      downloadList: [
        {
          name: 'P20',
          url: '/pdf/2021_IDC_handbook.pdf',
          enUrl: '/pdf/2021_IDC_handbook.pdf'
          // enUrl: '/pdf/2021_IDC_Guidelines.pdf'
        },
        {
          name: 'P21',
          url: '/pdf/IDC.jpg.zip',
          enUrl: '/pdf/IDC_en.jpg.zip'
        },
        {
          name: 'P22',
          url: '/pdf/2021_help.pdf',
          enUrl: '/pdf/2021_help_en.pdf'
        }
      ]
    }
  },
  created () {
  },
  mounted () {
  },
  computed: {
  },
  watch: {
  },
  methods: {
    toProduction () {
      this.$emit('toSignUp');
    },
    download (item) {
      if (this.$Cookies.get('cecc-lang') === 'en') {
        window.open(window.location.origin + item.enUrl);
      } else {
        window.open(window.location.origin + item.url);
      }
    }
  },
  components: {
    CommonFooter
  }
}
</script>
