<style lang="less" scoped >
.common-footer {
  width: 100%;
  height: 100px;
  background: rgba(15, 17, 24, 1);
  position: absolute;
  bottom: 0;
  left: 0;
  &-container {
    width: 77.3%;
    margin: 0 auto;
    color: rgba(255, 255, 255, 1);
    z-index: 9;
    display: flex;
    justify-content: space-between;
    &-item {
      p {
        padding: 0;
        margin: 4px 0;
        font-size: 12px;
        &:nth-child(1) {
          font-size: 14px;
          padding-top: 12px;
        }
      }
      img {
        width: 80px;
        margin-top: 12px;
      }
      &-right {
        margin-left: 32px;
      }
    }
    &-item1 {
      display: flex;
    }
  }
}
</style>
<template>
  <div class="common-footer">
    <div class="common-footer-container">
      <div class="common-footer-container-item">
        <p>
          指导单位 :
        </p>
        <p>
          长三角城市经济协调会办公室
        </p>
        <p>
          上海市文化创意产业推进领导小组办公室
        </p>
        <p>
          上海市合作交流办公室
        </p>
      </div>
      <div class="common-footer-container-item common-footer-container-item1">
        <div class="common-footer-container-item-left">
          <p>
            版权所有 :
          </p>
          <p>
            Copyright(C) 2009-2022
          </p>
          <p>
            长三角创意经济合作专业委员会
          </p>
        </div>
        <div class="common-footer-container-item-right">
          <img src="../assets/qrcode.jpeg"
               alt="">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'common-footer',
  props: {
    msg: String
  }
}
</script>
